import React, { useEffect, useState, useMemo } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CanvasJSReact from "@canvasjs/react-charts";
import { LuDownload } from "react-icons/lu";
import ExportCSV from "../project/ExportExcel";
import { TiFilter } from "react-icons/ti";
import Select from "react-select";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ClientWiseRPE = ({
  projectData,
  setProjectType,
  projectType,
  filteredData,
  setFilteredData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [sortedClients, setSortedClients] = useState([]);

  useEffect(() => {
    if (projectType.length > 0) {
      const filtered = projectData.filter(
        (item) =>
          item.project_type.name.toLowerCase() ===
          projectType[0].label.toLowerCase()
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(projectData);
    }
  }, [projectType, projectData, setFilteredData]);

  // Memoize the calculation of clientRPEList to avoid recalculating on every render
  const clientRPEList = useMemo(() => {
    const clientData = filteredData.reduce((acc, item) => {
      const clientName = item?.clients?.name;
      const achieveTarget = parseFloat(item.total_achievement);
      const cpi = parseFloat(item.cpi);
      const manDays = parseFloat(item.man_days);

      if (!acc[clientName]) {
        acc[clientName] = {
          totalRevenue: 0,
          totalManDays: 0,
        };
      }

      const revenue = achieveTarget * cpi;

      if (!isNaN(revenue) && revenue > 0) {
        acc[clientName].totalRevenue += revenue;

        if (!isNaN(manDays) && manDays > 0) {
          acc[clientName].totalManDays += manDays;
        }
      }

      return acc;
    }, {});

    return Object.keys(clientData).map((clientName) => {
      const client = clientData[clientName];
      const perEmployeeRevenue = client.totalManDays
        ? client.totalRevenue / client.totalManDays
        : 0;
      return {
        clientName,
        totalRevenue: client.totalRevenue.toFixed(2),
        perEmployeeRevenue: perEmployeeRevenue.toFixed(2),
      };
    });
  }, [filteredData]);

  // Apply sorting based on selected filter
  useEffect(() => {
    let sortedList = [...clientRPEList];

    if (selectedItem?.value === "A-Z") {
      sortedList.sort((a, b) => a.clientName.localeCompare(b.clientName));
    } else if (selectedItem?.value === "Z-A") {
      sortedList.sort((a, b) => b.clientName.localeCompare(a.clientName));
    } else if (selectedItem?.value === "High-Low") {
      sortedList.sort(
        (a, b) =>
          parseFloat(b.perEmployeeRevenue) - parseFloat(a.perEmployeeRevenue)
      );
    } else if (selectedItem?.value === "Low-High") {
      sortedList.sort(
        (a, b) =>
          parseFloat(a.perEmployeeRevenue) - parseFloat(b.perEmployeeRevenue)
      );
    }

    setSortedClients(sortedList);
  }, [selectedItem, clientRPEList]);

  // Filter the clients based on the search query
  const filteredClients = sortedClients.filter((client) =>
    client.clientName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Bar Chart options
  const chartOptions = {
    data: [
      {
        type: "column",
        dataPoints: filteredClients.map((client) => ({
          label: client.clientName,
          y: parseFloat(client.perEmployeeRevenue),
        })),
      },
    ],
  };

  const HandleFilter = () => {
    setShowFilter(!showFilter);
  };

  const filterOptions = [
    { value: "A-Z", label: "A-Z" },
    { value: "Z-A", label: "Z-A" },
    { value: "High-Low", label: "High-Low" },
    { value: "Low-High", label: "Low-High" },
  ];

  const handleSelectOption = (selectedOption) => {
    setSelectedItem(selectedOption);
  };

  return (
    <div className="relative w-full">
      <div className="relative w-full">
        <div className="flex justify-between items-center mb-2 w-full">
          <div className="w-2/5">
            <h3 className="text-base">Client Wise RPE Report</h3>
          </div>
          <div className="w-1/5">
            <input
              type="text"
              placeholder="Search Client"
              className="border p-1 rounded-md text-xs w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="w-2/5 flex justify-end items-center relative">
            <FormGroup>
              <FormControlLabel
                className="text-xs"
                control={
                  <Switch
                    onChange={() => setShowChart(!showChart)}
                    defaultChecked
                    size="small"
                  />
                }
                label={
                  <span className="text-xs w-full inline-block">
                    Chart On/Off
                  </span>
                }
              />
            </FormGroup>
            <div className="relative w-1/12 h-4">
              <TiFilter
                className="cursor-pointer text-base"
                onClick={HandleFilter}
              />
              {showFilter && (
                <Select
                  options={filterOptions}
                  onChange={handleSelectOption}
                  className={
                    "absolute right-16 z-40 bg-white shadow-md w-28 top-4 no-scrollbar"
                  }
                />
              )}
            </div>
            <div className="float-right text-right w-1/6">
              <ExportCSV
                data={filteredClients}
                name={<LuDownload />}
                downloadName={"Client_RPE.csv"}
              />
            </div>
          </div>
        </div>
      </div>

      {!showChart ? (
        <CanvasJSChart options={chartOptions} />
      ) : (
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200 text-sm">
          <thead className="bg-gray-50 p-2">
            <tr>
              <th>Client Name</th>
              <th>Total Revenue</th>
              <th>Per Employee Revenue</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-center">
            {filteredClients.map((client, ind) => (
              <tr className="bg-white text-sm" key={ind}>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black text-left">
                  {client.clientName}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black text-right">
                  ${client.totalRevenue}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black text-right">
                  ${client.perEmployeeRevenue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ClientWiseRPE;
