// import React, { useEffect, useState, useMemo } from "react";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import CanvasJSReact from "@canvasjs/react-charts";
// import ExportCSV from "../project/ExportExcel";
// import { TiFilter } from "react-icons/ti";
// import { LuDownload } from "react-icons/lu";
// import Select from "react-select";

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const AMWiseReport = ({
//   projectData,
//   userList,
//   setProjectType,
//   projectType,
//   setFilteredData,
// }) => {
//   const [amList, setAmList] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showChart, setShowChart] = useState(false);
//   const [showFilter, setShowFilter] = useState(false);
//   const [selectedItem, setSelectedItem] = useState("");
//   const [filteredAm, setFilteredAm] = useState([]);

//   // Fetch and set filtered data based on projectType
//   useEffect(() => {
//     if (projectType.length > 0) {
//       let filtered = projectData.filter(
//         (item) =>
//           item.project_type.name.toLowerCase() ===
//           projectType[0].label.toLowerCase()
//       );
//       setFilteredData(filtered);
//     } else {
//       setFilteredData(projectData);
//     }
//   }, [projectType, projectData, setFilteredData]);

//   // Fetch AM list from user list (filter based on department and roles)
//   useEffect(() => {
//     const fetchUserRole = async () => {
//       try {
//         const userListOperationDepartment = userList.filter(
//           (item) => item?.department?.id === 2
//         );
//         const AMList = userListOperationDepartment?.filter(
//           (item) =>
//             item.role.name === "Sr.Manager" ||
//             item.role.name === "Ass.Manager" ||
//             item.role.name === "Manager"
//         );
//         setAmList(AMList);
//       } catch (error) {
//         console.error("Error fetching user roles:", error);
//       }
//     };
//     fetchUserRole();
//   }, [userList]);

//   // Memoize segregated projects to avoid recalculating on every render
//   const segregatedProjects = useMemo(() => {
//     const projectInField = projectData.filter(
//       (item) => item.status === "In Progress" || item.status === "To Be Started"
//     );

//     const TOTALRPE = projectData.filter((item) => item.status === "Completed");
//     const CURRENTRPE = projectData.filter(
//       (item) => item.status === "In Progress"
//     );

//     return amList.map((am) => {
//       const projectsForAm = projectInField.filter(
//         (project) => project.project_assigned_by_manager === am.user_role.id
//       );
//       console.log("🚀 ~ returnamList.map ~ projectsForAm:", projectsForAm);

//       const totalRpeData = TOTALRPE.reduce(
//         (acc, project) => {
//           const achievement = parseFloat(project.total_achievement || 0);
//           const cpi = parseFloat(project.cpi || 0);
//           const manDays = parseFloat(project.man_days || 0);
//           if (achievement > 0 && cpi > 0 && manDays > 0) {
//             acc.totalRevenue += achievement * cpi;
//             acc.totalManDays += manDays;
//           }
//           return acc;
//         },
//         { totalRevenue: 0, totalManDays: 0 }
//       );

//       const currentRpeData = CURRENTRPE.reduce(
//         (acc, project) => {
//           const achievement = parseFloat(project.total_achievement || 0);
//           const cpi = parseFloat(project.cpi || 0);
//           const manDays = parseFloat(project.man_days || 0);
//           if (achievement > 0 && cpi > 0 && manDays > 0) {
//             acc.totalRevenue += achievement * cpi;
//             acc.totalManDays += manDays;
//           }
//           return acc;
//         },
//         { totalRevenue: 0, totalManDays: 0 }
//       );

//       return {
//         amName: am.user.name,
//         amId: am.user_role.id,
//         projects: projectsForAm,
//         TOTALRPE:
//           totalRpeData.totalManDays > 0
//             ? totalRpeData.totalRevenue / totalRpeData.totalManDays
//             : 0,
//         CURRENTRPE:
//           currentRpeData.totalManDays > 0
//             ? currentRpeData.totalRevenue / currentRpeData.totalManDays
//             : 0,
//       };
//     });
//   }, [amList, projectData]);

//   useEffect(() => {
//     let filtered = segregatedProjects.filter((am) =>
//       am?.amName?.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     if (selectedItem?.value === "A-Z") {
//       filtered = filtered.sort((a, b) => a.amName.localeCompare(b.amName));
//     } else if (selectedItem?.value === "Z-A") {
//       filtered = filtered.sort((a, b) => b.amName.localeCompare(a.amName));
//     } else if (selectedItem?.value === "High-Low") {
//       filtered = filtered.sort((a, b) => b.CURRENTRPE - a.CURRENTRPE);
//     } else if (selectedItem?.value === "Low-High") {
//       filtered = filtered.sort((a, b) => a.CURRENTRPE - b.CURRENTRPE);
//     }

//     setFilteredAm(filtered);
//   }, [searchQuery, selectedItem, segregatedProjects]);

//   const chartOptions = {
//     data: [
//       {
//         type: "column",
//         dataPoints: filteredAm.map((am) => ({
//           label: am.amName,
//           y: parseFloat(am.CURRENTRPE),
//         })),
//       },
//     ],
//   };

//   const handleFilter = () => {
//     setShowFilter(!showFilter);
//   };

//   const filterOptions = [
//     { value: "A-Z", label: "A-Z" },
//     { value: "Z-A", label: "Z-A" },
//     { value: "High-Low", label: "High-Low" },
//     { value: "Low-High", label: "Low-High" },
//   ];

//   const handleSelectOption = (selectedOption) => {
//     setSelectedItem(selectedOption);
//   };

//   return (
//     <div className="">
//       <div className="relative w-full">
//         <div className="flex justify-between items-center mb-2 w-full">
//           <div className="w-2/5">
//             <h3 className="text-base">AM Wise RPE Report</h3>
//           </div>
//           <div className="w-1/5">
//             <input
//               type="text"
//               placeholder="Search AM"
//               className="border p-1 rounded-md text-xs w-full"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//           </div>
//           <div className="w-2/5 flex justify-end items-center relative">
//             <FormGroup>
//               <FormControlLabel
//                 className="text-xs"
//                 control={
//                   <Switch
//                     onChange={() => setShowChart(!showChart)}
//                     defaultChecked
//                     size="small"
//                   />
//                 }
//                 label={
//                   <span className="text-xs w-full inline-block">
//                     Chart On/Off
//                   </span>
//                 }
//               />
//             </FormGroup>
//             <div className="relative w-1/12 h-4">
//               <TiFilter
//                 className="cursor-pointer text-base"
//                 onClick={handleFilter}
//               />
//               {showFilter && (
//                 <Select
//                   options={filterOptions}
//                   onChange={handleSelectOption}
//                   className={
//                     "absolute right-16 z-40 bg-white shadow-md w-28 top-4 no-scrollbar"
//                   }
//                 />
//               )}
//             </div>
//             <div className="float-right text-right w-1/6">
//               <ExportCSV
//                 data={filteredAm}
//                 name={<LuDownload />}
//                 downloadName={"AM_RPE.csv"}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {!showChart ? (
//         <div>
//           <CanvasJSChart options={chartOptions} />
//         </div>
//       ) : (
//         <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200 text-xs">
//           <thead className="bg-gray-50 text-sm">
//             <tr>
//               <th>AM Name</th>
//               <th>Project in field</th>
//               <th>Total RPE</th>
//               <th>Current RPE</th>
//             </tr>
//           </thead>
//           <tbody className="divide-y divide-gray-200 text-center text-xs">
//             {filteredAm.map((am, ind) => (
//               <tr className="bg-white" key={ind}>
//                 <td className="px-4 py-2 whitespace-nowrap text-left text-sm text-black">
//                   {am.amName}
//                 </td>
//                 <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
//                   {am.projects.length}
//                 </td>
//                 <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
//                   {am.TOTALRPE.toFixed(2)}
//                 </td>
//                 <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
//                   {am.CURRENTRPE.toFixed(2)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </div>
//   );
// };

// export default AMWiseReport;

import React, { useEffect, useState, useMemo } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CanvasJSReact from "@canvasjs/react-charts";
import ExportCSV from "../project/ExportExcel";
import { TiFilter } from "react-icons/ti";
import { LuDownload } from "react-icons/lu";
import Select from "react-select";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AMWiseReport = ({
  projectData,
  userList,
  setProjectType,
  projectType,
  setFilteredData,
}) => {
  const [amList, setAmList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [filteredAm, setFilteredAm] = useState([]);

  // Fetch and set filtered data based on projectType
  useEffect(() => {
    if (projectType.length > 0) {
      let filtered = projectData.filter(
        (item) =>
          item.project_type.name.toLowerCase() ===
          projectType[0].label.toLowerCase()
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(projectData);
    }
  }, [projectType, projectData, setFilteredData]);

  // Fetch AM list from user list (filter based on department and roles)
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const userListOperationDepartment = userList.filter(
          (item) => item?.department?.id === 2
        );
        const AMList = userListOperationDepartment?.filter(
          (item) =>
            item.role.name === "Sr.Manager" ||
            item.role.name === "Ass.Manager" ||
            item.role.name === "Manager"
        );
        setAmList(AMList);
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    };
    fetchUserRole();
  }, [userList]);

  // Memoize segregated projects to avoid recalculating on every render
  const segregatedProjects = useMemo(() => {
    return amList.map((am) => {
      const projectsForAm = projectData.filter(
        (project) => project.project_assigned_by_manager === am.user_role.id
      );

      if (projectsForAm.length === 0) {
        // If no projects for AM, mark RPE values as 0
        return {
          amName: am.user.name,
          amId: am.user_role.id,
          projects: [],
          TOTALRPE: 0,
          CURRENTRPE: 0,
        };
      } else {
        const totalRpeData = projectsForAm.reduce(
          (acc, project) => {
            const sample = parseFloat(project.sample || 0);
            const cpi = parseFloat(project.cpi || 0);
            const manDays = parseFloat(project.man_days || 0);
            if (sample > 0 && cpi > 0 && manDays > 0) {
              acc.totalRevenue += sample * cpi;
              acc.totalManDays += manDays;
              acc.totalSample += sample;
            }
            return acc;
          },
          { totalRevenue: 0, totalManDays: 0, totalSample: 0 }
        );

        return {
          amName: am.user.name,
          amId: am.user_role.id,
          projects: projectsForAm,
          TOTALRPE:
            totalRpeData.totalManDays > 0
              ? totalRpeData.totalRevenue / totalRpeData.totalManDays
              : 0,
          CURRENTRPE:
            totalRpeData.totalManDays > 0
              ? totalRpeData.totalSample / totalRpeData.totalManDays
              : 0,
        };
      }
    });
  }, [amList, projectData]);

  useEffect(() => {
    let filtered = segregatedProjects.filter((am) =>
      am?.amName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedItem?.value === "A-Z") {
      filtered = filtered.sort((a, b) => a.amName.localeCompare(b.amName));
    } else if (selectedItem?.value === "Z-A") {
      filtered = filtered.sort((a, b) => b.amName.localeCompare(a.amName));
    } else if (selectedItem?.value === "High-Low") {
      filtered = filtered.sort((a, b) => b.CURRENTRPE - a.CURRENTRPE);
    } else if (selectedItem?.value === "Low-High") {
      filtered = filtered.sort((a, b) => a.CURRENTRPE - b.CURRENTRPE);
    }

    setFilteredAm(filtered);
  }, [searchQuery, selectedItem, segregatedProjects]);

  const chartOptions = {
    data: [
      {
        type: "column",
        dataPoints: filteredAm.map((am) => ({
          label: am.amName,
          y: parseFloat(am.CURRENTRPE),
        })),
      },
    ],
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const filterOptions = [
    { value: "A-Z", label: "A-Z" },
    { value: "Z-A", label: "Z-A" },
    { value: "High-Low", label: "High-Low" },
    { value: "Low-High", label: "Low-High" },
  ];

  const handleSelectOption = (selectedOption) => {
    setSelectedItem(selectedOption);
  };

  return (
    <div className="">
      <div className="relative w-full">
        <div className="flex justify-between items-center mb-2 w-full">
          <div className="w-2/5">
            <h3 className="text-base">AM Wise RPE Report</h3>
          </div>
          <div className="w-1/5">
            <input
              type="text"
              placeholder="Search AM"
              className="border p-1 rounded-md text-xs w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="w-2/5 flex justify-end items-center relative">
            <FormGroup>
              <FormControlLabel
                className="text-xs"
                control={
                  <Switch
                    onChange={() => setShowChart(!showChart)}
                    defaultChecked
                    size="small"
                  />
                }
                label={
                  <span className="text-xs w-full inline-block">
                    Chart On/Off
                  </span>
                }
              />
            </FormGroup>
            <div className="relative w-1/12 h-4">
              <TiFilter
                className="cursor-pointer text-base"
                onClick={handleFilter}
              />
              {showFilter && (
                <Select
                  options={filterOptions}
                  onChange={handleSelectOption}
                  className={
                    "absolute right-16 z-40 bg-white shadow-md w-28 top-4 no-scrollbar"
                  }
                />
              )}
            </div>
            <div className="float-right text-right w-1/6">
              <ExportCSV
                data={filteredAm}
                name={<LuDownload />}
                downloadName={"AM_RPE.csv"}
              />
            </div>
          </div>
        </div>
      </div>

      {!showChart ? (
        <div>
          <CanvasJSChart options={chartOptions} />
        </div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200 text-xs">
          <thead className="bg-gray-50 text-sm">
            <tr>
              <th>AM Name</th>
              <th>Project in field</th>
              <th>Total RPE</th>
              <th>Current RPE</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-center text-xs">
            {filteredAm.map((am, ind) => (
              <tr className="bg-white" key={ind}>
                <td className="px-4 py-2 whitespace-nowrap text-left text-sm text-black">
                  {am.amName}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
                  {am.projects.length}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
                  {am.TOTALRPE.toFixed(2)}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-black">
                  {am.CURRENTRPE.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AMWiseReport;
