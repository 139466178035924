// import { useState } from "react";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
// import Button from "./Button";
// const DateRangeFilter = ({ dateRange, setDateRange }) => {
//   const [openDateRange, setOpenDateRange] = useState(false);
//   // const ref1 = useRef();
//   // const ref = useRef();

//   // const handleDate = (e) => {
//   //   const { name, value } = e.target;
//   //   setDateRange((prev) => ({ ...prev, [name]: value }));
//   // };
//   const shortcutsItems = [
//     {
//       label: "This Week",
//       getValue: () => {
//         const today = dayjs();
//         return [today.startOf("week"), today.endOf("week")];
//       },
//     },
//     {
//       label: "Last Week",
//       getValue: () => {
//         const today = dayjs();
//         const prevWeek = today.subtract(7, "day");
//         return [prevWeek.startOf("week"), prevWeek.endOf("week")];
//       },
//     },
//     {
//       label: "Last 7 Days",
//       getValue: () => {
//         const today = dayjs();
//         return [today.subtract(7, "day"), today];
//       },
//     },
//     {
//       label: "Current Month",
//       getValue: () => {
//         const today = dayjs();
//         return [today.startOf("month"), today.endOf("month")];
//       },
//     },
//     {
//       label: "Prev Month",
//       getValue: () => {
//         const today = dayjs();
//         const startOfPreviousMonth = today.startOf("month").add(-1, "day");
//         return [
//           startOfPreviousMonth.startOf("month"),
//           startOfPreviousMonth.endOf("month"),
//         ];
//       },
//     },
//     { label: "Reset", getValue: () => [null, null] },
//   ];
//   return (
//     // <div className="w-full flex">
//     //   <input
//     //     ref={ref}
//     //     type={"text"}
//     //     name={"startDate"}
//     //     className={
//     //       " rounded-full p-2 m-1 border border-black bg-transparent w-28"
//     //     }
//     //     onChange={handleDate}
//     //     placeholder={"Start Date"}
//     //     value={dateRange.startDate}
//     //     onFocus={() => (ref.current.type = "date")}
//     //     onBlur={() => (ref.current.type = "text")}
//     //   />
//     //   <input
//     //     ref={ref1}
//     //     type={"text"}
//     //     name={"endDate"}
//     //     className={"p-2 m-1 border border-black rounded-full w-28"}
//     //     onChange={handleDate}
//     //     placeholder={"End Date"}
//     //     value={dateRange.endDate}
//     //     onFocus={() => (ref1.current.type = "date")}
//     //     onBlur={() => (ref1.current.type = "text")}
//     //   />
//     // </div>
//     <div className="relative w-full">
//       <Button
//         name={"Select Date Range"}
//         className={"bg-transparent rounded-full border-black border p-1 m-1"}
//         onClick={() => setOpenDateRange(!openDateRange)}
//       />
//       {openDateRange && (
//         <div className="absolute top-10 left-0 w-[50rem] overflow-visible bg-white border z-50">
//           <div className="w-full">
//             <LocalizationProvider dateAdapter={AdapterDayjs}>
//               <StaticDateRangePicker
//                 slotProps={{
//                   shortcuts: {
//                     items: shortcutsItems,
//                   },
//                   actionBar: { actions: [] },
//                 }}
//                 calendars={2}
//               />
//             </LocalizationProvider>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateRangeFilter;

import { useState } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Button from "./Button";

const DateRangeFilter = ({ dateRange, setDateRange }) => {
  const [openDateRange, setOpenDateRange] = useState(false);

  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Prev Month",
      getValue: () => {
        const today = dayjs();
        const startOfPreviousMonth = today.startOf("month").add(-1, "day");
        return [
          startOfPreviousMonth.startOf("month"),
          startOfPreviousMonth.endOf("month"),
        ];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  return (
    <div className="relative w-10/12">
      <Button
        name={"Select Date Range"}
        className={
          "bg-transparent rounded-full border-black border p-[6px] m-1"
        }
        onClick={() => setOpenDateRange(!openDateRange)}
      />
      {openDateRange && (
        <div className="absolute top-11 left-0 w-[47rem] overflow-visible bg-white border z-50">
          <div className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                // value={[dateRange.startDate, dateRange.endDate]}
                onChange={(newValue) => {
                  setDateRange({
                    startDate: newValue[0],
                    endDate: newValue[1],
                  });
                }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                  actionBar: { actions: [] },
                }}
                calendars={2}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeFilter;
